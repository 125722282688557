<template>
    <div class="video-container" v-bind="$attrs">
        <iframe
            ref="iframe"
            :src="src"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            autoplay="1"
            width="100%"
            height="100%"
            class="video-iframe"
        />
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
        }
    },
}
</script>

<style>
.video-container {
    position: relative;
    padding-top: 56.25%;
}

.video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>