<template>
    <v-item-group v-model="model.value"
        :style="{
            'pointer-events': readonly ? 'none' : 'auto',
        }"
    >
        <v-row :style="scroll ? 'height: 450px; overflow-y: scroll' : ''">
            <v-col v-for="(item, index) in items" :key="index" v-bind="colAttrs" class="text-center">
                
                <div v-if="item.title" class="mb-4" style="text-align: left;">
                    <h3>{{ item.title }}</h3>
                </div>

                <v-item :value="item.value">
                    <template v-slot:default="{ active, toggle }">
                        <div class="d-flex w-full justify-start">
                            <div v-if="checkboxOutside" class="white">
                                <v-btn
                                    icon
                                    dark
                                    color="primary"
                                    class="mr-4"
                                    @click="toggle"                                    
                                >
                                    <v-icon>
                                        {{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                                    </v-icon>
                                </v-btn>
                            </div>

                            <div class="flex-grow-1 overflow-hidden">
                                <v-img                                    
                                    :src="item.src"
                                    :alt="item.text"
                                    :height="height"
                                    contain
                                    :width="width"
                                    :style="{
                                        'opacity': active ? '1' : '0.7',
                                        'cursor': 'pointer',
                                        'border-radius': '4px',
                                        'margin': question.config?.row ? 'inherit' : 'auto'
                                    }"
                                    @click="toggle"
                                >
                                    <v-btn
                                        icon
                                        dark
                                        color="primary"
                                        style="position: absolute;top: 0;left: 0;"
                                        v-if="!checkboxOutside"
                                    >
                                        <v-icon>
                                            {{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                                        </v-icon>
                                    </v-btn>
                                </v-img>
                            </div>
    
                        </div>
                    </template>
                </v-item>

                <v-row v-if="item.url && item.btn_label">
                    <v-col cols="12" class="mt-4 mb-4">
                        <v-btn small color="primary" @click="openUrl(item.url)">{{ item.btn_label }}</v-btn>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
    </v-item-group>
</template>

<script>
export default {
    name: 'DynamicFormFieldImageSelect',
    props: {
        value: {
            type: Object,
            default: () => ({ value: null })
        },
        question: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        items(){
            return this.question.config?.items || [];
        },
        height(){
            return this.question.config?.height || 200;
        },
        width(){
            return this.question.config?.width || 200;
        },
        checkboxOutside(){
            return this.question.config?.checkboxOutside || false;
        },
        scroll(){
            return this.question.config?.scroll || false;
        },
        colAttrs(){
            if (this.question.config?.row || this.readonly){
                return {
                    cols: 12
                }
            }

            return {
                cols: 12,
                md: 6
            }
        }
    },
    methods: {
        openUrl(url) {
            window.open(url, "_blank");
        }
    }
}
</script>