<template>
    <v-row v-if="model.value">
        <v-col v-for="(item, index) in items" :key="item.id" cols="12" class="mb-4">
            <v-card outlined>
                <v-card-title>
                    Horário {{ index + 1 }}
                </v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12">
                            <v-select v-model="model.value[index].days" label="Dias" placeholder="Dias" multiple :items="days" item-text="title" item-value="id" outlined hide-details :readonly="readonly" />
                        </v-col>

                        <v-col cols="6">
                            <v-select v-model="model.value[index].open" label="Abre" placeholder="Abre" :items="hours" outlined hide-details :readonly="readonly" />
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="model.value[index].close" label="Fecha" placeholder="Fecha" :items="hours" outlined hide-details :readonly="readonly" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn v-if="!readonly" @click="removeItem(index)" color="error" tabindex="-1">
                        Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-col v-if="!readonly" cols="12">
            <v-btn color="primary" @click="addItem">
                Adicionar horário
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({ value: null })
        },
        question: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            days: [
                { id: "MON", title: "Segunda-feira" },
                { id: "TUE", title: "Terça-feira" },
                { id: "WED", title: "Quarta-feira" },
                { id: "THU", title: "Quinta-feira" },
                { id: "FRI", title: "Sexta-feira" },
                { id: "SAT", title: "Sábado" },
                { id: "SUN", title: "Domingo" }
            ],
            hours: [
                "00:00",
                "00:30",
                "01:00",
                "01:30",
                "02:00",
                "02:30",
                "03:00",
                "03:30",
                "04:00",
                "04:30",
                "05:00",
                "05:30",
                "06:00",
                "06:30",
                "07:00",
                "07:30",
                "08:00",
                "08:30",
                "09:00",
                "09:30",
                "10:00",
                "10:30",
                "11:00",
                "11:30",
                "12:00",
                "12:30",
                "13:00",
                "13:30",
                "14:00",
                "14:30",
                "15:00",
                "15:30",
                "16:00",
                "16:30",
                "17:00",
                "17:30",
                "18:00",
                "18:30",
                "19:00",
                "19:30",
                "20:00",
                "20:30",
                "21:00",
                "21:30",
                "22:00",
                "22:30",
                "23:00",
                "23:30"
            ]
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        items() {
            if (!Array.isArray(this.model.value)) return []

            return this.model.value.map((item, index) => ({
                id: index,
                days: item.days,
                open: item.open,
                close: item.close,
            }))
        },
    },
    methods: {
        addItem() {
            this.model.value.push({
                days: '',
                open: '',
                close: ''
            })
        },
        removeItem(index) {
            this.model.value.splice(index, 1)
        }
    },
    mounted() {
        if (!Array.isArray(this.model.value) || !this.model.value) {
            this.model.value = []
        }
    },
}
</script>