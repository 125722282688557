import Api from "@/lib/Api";

export default {
    namespaced: true,
    state: {
        items: [],

        // occupation area fields
        occupationCategories: [
            { text: "Cirurgia", value: "Cirurgia" },
            { text: "Procedimento", value: "Procedimento" },
            { text: "Enfermidade", value: "Enfermidade" },
            { text: "Área de atuação (Ex: Nutrologia)", value: "Área de atuação" },
        ],
        occupationBaseFields: ['category', 'name', 'introduction', 'howTheAttendanceIsDone'],
        occupationCategoriesFields: {
            'Cirurgia': ['surgeryWhenIsRecommended', 'surgeryInterventionTypes'],
            'Procedimento': ['procedureRecommendations', 'procedureResults'],
            'Enfermidade': ['diseaseSymptoms', 'diseaseDiagnosis', 'diseaseTreatment'],
            'Área de atuação': ['areaWhenLookFor', 'areaAdvantages']
        }
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload
        }
    },
    actions: {
        async load({ commit }) {
            const response = await Api.getFormResponses();

            if (response.error) return

            commit('setItems', response.message)
        }
    }
}