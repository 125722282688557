import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from '@/store'
import "@/assets/global.css";
import VueApexCharts from "vue-apexcharts";

import './plugins/components'
import './plugins/globals'

Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);

const app = new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
})

app.$mount("#app");
