<template>
  <v-row v-if="model.value">
    <v-col cols="12" v-if="errors.length > 0">
      <v-alert type="error" outlined>
        {{ errors[0] }}
      </v-alert>
    </v-col>

    <v-col cols="12">
      <v-select v-model="model.value.category" label="Categoria" v-bind="fieldAttrs" :items="categories" :error="errors.length > 0 && !model.value.category" />
    </v-col>

    <v-col cols="12">
      <v-text-field v-model="model.value.name" label="Nome da área de atuação" placeholder="Nome da área de atuação" v-bind="fieldAttrs" :error="errors.length > 0 && !model.value.name" />
    </v-col>

    <v-col cols="12">
      <v-textarea v-model="model.value.introduction" label="Introdução (o que é)" placeholder="Introdução (o que é)" v-bind="fieldAttrs" :error="errors.length > 0 && !model.value.introduction" />
    </v-col>

    <template v-if="model.value.category === 'Cirurgia'">
      <v-col cols="12">
        <v-textarea v-model="model.value.surgeryWhenIsRecommended" :error="errors.length > 0 && !model.value.surgeryWhenIsRecommended" label="Quando é indicada" placeholder="Quando é indicada" v-bind="fieldAttrs" />
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="model.value.surgeryInterventionTypes" :error="errors.length > 0 && !model.value.surgeryInterventionTypes" label="Tipos de Intervenções (Explicar como é feita a cirurgia)" placeholder="Tipos de Intervenções (Explicar como é feita a cirurgia)" v-bind="fieldAttrs" />
      </v-col>
    </template>

    <template v-if="model.value.category === 'Procedimento'">
      <v-col cols="12">
        <v-textarea v-model="model.value.procedureRecommendations" :error="errors.length > 0 && !model.value.procedureRecommendations" label="Indicações" placeholder="Indicações" v-bind="fieldAttrs" />
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="model.value.procedureResults" :error="errors.length > 0 && !model.value.procedureResults" label="Resultados e benefícios" placeholder="Resultados e benefícios" v-bind="fieldAttrs" />
      </v-col>
    </template>

    <template v-if="model.value.category === 'Enfermidade'">
      <v-col cols="12">
        <v-textarea v-model="model.value.diseaseSymptoms" :error="errors.length > 0 && !model.value.diseaseSymptoms" label="Sintomas e queixas comuns" placeholder="Sintomas e queixas comuns" v-bind="fieldAttrs" />
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="model.value.diseaseDiagnosis" :error="errors.length > 0 && !model.value.diseaseDiagnosis" label="Diagnóstico" placeholder="Diagnóstico" v-bind="fieldAttrs" />
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="model.value.diseaseTreatments" :error="errors.length > 0 && !model.value.diseaseTreatments" label="Tratamentos" placeholder="Tratamentos" v-bind="fieldAttrs" />
      </v-col>
    </template>

    <template v-if="model.value.category === 'Área de atuação'">
      <v-col cols="12">
        <v-textarea v-model="model.value.areaWhenLookFor" :error="errors.length > 0 && !model.value.areaWhenLookFor" label="Quando buscar um profissional dessa area?" placeholder="Quando buscar um profissional dessa area?" v-bind="fieldAttrs" />
      </v-col>
      <v-col cols="12">
        <v-textarea v-model="model.value.areaAdvantages" :error="errors.length > 0 && !model.value.areaAdvantages" label="Benefícios" placeholder="Benefícios" v-bind="fieldAttrs" />
      </v-col>
    </template>

    <v-col cols="12">
      <v-textarea v-model="model.value.howTheAttendanceIsDone" :error="errors.length > 0 && !model.value.howTheAttendanceIsDone" label="Como você realiza o tratamento/atendimento?" placeholder="Como você realiza o tratamento/atendimento?" v-bind="fieldAttrs" />
    </v-col>

    <v-col cols="12" v-if="isAdmin">
      <div>
        <v-row v-for="n in 4" :key="`image_picker_${n}`">
          <v-col cols="12" sm="4" md="3">
            <h3 class="mb-2">Imagem {{ n }}</h3>

            <v-card height="140" max-width="280">
              <v-img v-if="model.value[`image${n}`]" :src="model.value[`image${n}`]" height="140" />
              <div v-else class="w-full h-full primary d-flex justify-center">
                <v-icon color="white" style="font-size:100px">mdi-image</v-icon>
              </div>
            </v-card>
          </v-col>

          <v-col v-if="!readonly" cols="12" sm="auto" class="d-flex align-center text-center text-sm-left">
            <div>
              <v-btn color="primary" class="mb-2 mb-sm-0 mr-sm-2" :loading="uploading" @click="pickFile(`image${n}`)">
                Alterar imagem
              </v-btn>
              <v-btn color="error" :disabled="uploading" @click="removeImage(`image${n}`)">
                Remover imagem
              </v-btn>

              <div class="w-full mt-4">
                Imagens permitidas: JPG, PNG e com tamanho máximo de 2MB.
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>

  </v-row>
</template>

<script>
export default {
  name: 'DynamicFormFieldOccupationForm',
  props: {
    value: {
      type: Object,
      default: () => ({
        value: null
      })
    },
    question: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ uploading: false }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    fieldAttrs() {
      return {
        readonly: this.readonly,
        outlined: true,
        hideDetails: true,
      }
    },
    categories() {
      return this.$store.state.formResponse.occupationCategories
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    isAdmin() {
      return this.currentUser?.admin;
    },
  },
  mounted() {
    if (!this.model.value) {
      this.model.value = {
        category: null,
        name: null,
        introduction: null,
        image1: 'https://ramp-up-hml.s3.sa-east-1.amazonaws.com/images/95005de2-2e7f-40e2-b9b3-09d449c807b2.jpg',
        image2: 'https://ramp-up-hml.s3.sa-east-1.amazonaws.com/images/95005de2-2e7f-40e2-b9b3-09d449c807b2.jpg',
        image3: 'https://ramp-up-hml.s3.sa-east-1.amazonaws.com/images/95005de2-2e7f-40e2-b9b3-09d449c807b2.jpg',
        image4: 'https://ramp-up-hml.s3.sa-east-1.amazonaws.com/images/95005de2-2e7f-40e2-b9b3-09d449c807b2.jpg'
      }
    }
  },
  methods: {
    async pickFile(image) {
      const input = document.createElement('input')

      input.type = 'file'
      input.accept = 'image/jpeg, image/png'

      input.onchange = (e) => {
        const file = e.target.files[0]

        this.upload(file, image)
      }

      input.click()
    },
    async upload(file, image) {
      this.uploading = true;

      const response = await this.$api.uploadImage(file);

      if (response.error) {
        this.uploading = false;
        this.$toast('error', response.message);
        return
      }

      this.model.value[image] = response.message;

      setTimeout(() => {
        this.uploading = false;
      }, 500);
    },
    removeImage(image) {
      this.model.value[image] = null;
      this.$forceUpdate();
    }
  }
}
</script>