var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-item-group',{style:({
        'pointer-events': _vm.readonly ? 'none' : 'auto',
    }),model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}},[_c('v-row',{style:(_vm.scroll ? 'height: 450px; overflow-y: scroll' : '')},_vm._l((_vm.items),function(item,index){return _c('v-col',_vm._b({key:index,staticClass:"text-center"},'v-col',_vm.colAttrs,false),[(item.title)?_c('div',{staticClass:"mb-4",staticStyle:{"text-align":"left"}},[_c('h3',[_vm._v(_vm._s(item.title))])]):_vm._e(),_c('v-item',{attrs:{"value":item.value},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('div',{staticClass:"d-flex w-full justify-start"},[(_vm.checkboxOutside)?_c('div',{staticClass:"white"},[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":"","dark":"","color":"primary"},on:{"click":toggle}},[_c('v-icon',[_vm._v(" "+_vm._s(active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline')+" ")])],1)],1):_vm._e(),_c('div',{staticClass:"flex-grow-1 overflow-hidden"},[_c('v-img',{style:({
                                    'opacity': active ? '1' : '0.7',
                                    'cursor': 'pointer',
                                    'border-radius': '4px',
                                    'margin': _vm.question.config?.row ? 'inherit' : 'auto'
                                }),attrs:{"src":item.src,"alt":item.text,"height":_vm.height,"contain":"","width":_vm.width},on:{"click":toggle}},[(!_vm.checkboxOutside)?_c('v-btn',{staticStyle:{"position":"absolute","top":"0","left":"0"},attrs:{"icon":"","dark":"","color":"primary"}},[_c('v-icon',[_vm._v(" "+_vm._s(active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline')+" ")])],1):_vm._e()],1)],1)])]}}],null,true)}),(item.url && item.btn_label)?_c('v-row',[_c('v-col',{staticClass:"mt-4 mb-4",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openUrl(item.url)}}},[_vm._v(_vm._s(item.btn_label))])],1)],1):_vm._e()],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }