import Vue from "vue";

import { emitToastr } from "@/lib/Utils";
import Api from "@/lib/Api";

import { format } from "date-fns";

Vue.prototype.$toast = emitToastr;
Vue.prototype.$api = Api;
Vue.prototype.$vindi_statuses = [{ id: 'active', color: 'success', name: 'Ativo' }, { id: 'inactive', color: 'warning', name: 'Inativo' }, { id: 'canceled', color: 'error lighten-1', name: 'Cancelada' }];

Vue.prototype.$user_permissions = [
    { id: 'DEFAULT', name: "Website" },
    { id: 'SECRETARY_ASSISTANT', name: "Assistente de secretária" }
];

Vue.prototype.$date = {
    format: (date, pattern = "dd/MM/yyyy HH:mm") => {
        return format(date, pattern);
    }
}
